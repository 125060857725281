import { PublicationIntentService } from "../../data/publication-intent.service";

export class CountPendingPublicationIntentsInteractor {
  constructor(
    private readonly publicationIntentService: PublicationIntentService,
  ) {}

  public async execute(campaignId: number, partnerId: number): Promise<number> {
    return this.publicationIntentService.countPendingForCampaignPartner(
      campaignId,
      partnerId,
    );
  }
}
