<div *ngIf="type === PostLogStatus.Published" class="post-stats-wrapper">
  <div
    class="post-stats-box"
    [ngClass]="{
      'post-stats-box--carousel': post.mediaType === TYPE_CAROUSEL
    }"
    [@flipStatsState]="showPostFlippableStats(post) ? statsView : null"
  >
    <ng-container *ngIf="showPostFlippableStats(post)">
      <!-- Facebook post and ad-->
      <app-campaign-post-stats-facebook-post
        [post]="post"
        [isFlippable]="true"
        [isVisible]="!isShowingBack"
        [campaign]="campaign"
        (flipPostAdStatsEvent)="togglePostAdStats()"
      />
      <app-campaign-post-stats-ad
        [post]="post"
        [hasPostStats]="true"
        [isFlippable]="true"
        [isVisible]="isShowingBack"
        [campaign]="campaign"
        (flipPostAdStatsEvent)="togglePostAdStats()"
        (openAdConfigDialog)="openAdConfig()"
      />
    </ng-container>
    <ng-container *ngIf="!showPostFlippableStats(post)">
      <app-campaign-post-stats-facebook-post
        *ngIf="post.isFacebookPost && !post.isDarkPost"
        [post]="post"
        [campaign]="campaign"
        [isFlippable]="false"
        (openPromoteAdDialog)="openPromotePost(post)"
      />
      <app-campaign-post-stats-linkedin-post
        *ngIf="post.isLinkedInPost"
        [post]="post"
        [campaign]="campaign"
      />
      <app-campaign-post-stats-ad
        *ngIf="!post.isLinkedInPost && post.hasAd"
        [post]="post"
        [hasPostStats]="false"
        [isFlippable]="false"
        [campaign]="campaign"
        (openAdConfigDialog)="openAdConfig()"
      />
    </ng-container>
  </div>
</div>
<div *ngIf="type === PostLogStatus.Scheduled" class="post-stats-wrapper">
  <app-campaign-post-stats-not-published
    [campaign]="campaign"
    [post]="post"
    (openEditPostDialog)="openEditPost()"
    (openCancelPostDialog)="openCancelPost()"
  />
</div>
