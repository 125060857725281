import { Component, Input, OnInit, signal } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { LinkedInPostContentType } from "../../../enums/campaignPost.enums";
import { BrandLinkedInPostLog } from "../../../models/brandLinkedInPostLog";
import { Campaign } from "../../../models/campaign";
import { PartnerCampaign } from "../../../models/partnerCampaign";
import { PartnerLinkedInPostLog } from "../../../models/partnerLinkedInPostLog";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { getDateTimeFormatSignal } from "../../../services/date.service";
import { ToolTipIcon, TooltipComponent } from "../../tooltip/tooltip.component";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";

@Component({
  selector: "app-campaign-post-stats-linkedin-post",
  templateUrl: "./campaign-post-stats-linkedin-post.component.html",
  styleUrl: "./campaign-post-stats-linkedin-post.component.scss",
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    SharedPipesModule,
    TooltipComponent,
    TranslateModule,
    MatIcon,
    DatePipe,
  ],
})
export class CampaignPostStatsLinkedinPostComponent implements OnInit {
  @Input({ required: true }) public post!:
    | PartnerLinkedInPostLog
    | BrandLinkedInPostLog;
  @Input({ required: true }) public campaign!: Campaign;
  protected readonly areStatsValid = signal(false);

  protected readonly ToolTipIcon = ToolTipIcon;
  protected readonly ContentType = LinkedInPostContentType;
  protected reconnectToFacebookText = "campaign.post.stats.connectToFacebook";
  protected readonly dateTimeFormat = getDateTimeFormatSignal();

  public ngOnInit(): void {
    if (!(this.campaign instanceof PartnerCampaign)) {
      this.reconnectToFacebookText = "campaign.post.stats.partnerNotConnected";
    }

    this.areStatsValid.set(
      this.post.hasValidMetrics ||
        this.post.likes > 0 ||
        this.post.comments > 0 ||
        this.post.shares > 0 ||
        this.post.clicks > 0 ||
        this.post.impressions > 0,
    );
  }
}
