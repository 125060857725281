<div
  class="post-stats post-stats-box__side"
  [ngClass]="{
    'post-stats-box__back': post.hasAd && isFlippable,
    'is-flipped': !isVisible,
    'is-brand': isBrand
  }"
  [attr.data-post-log-id]="post.id"
  [attr.data-ad-id]="post.ad?.id"
>
  <div class="promoted-badge">{{ "shared.promoted" | translate }}</div>
  <div class="post-stats-content">
    <h2>
      <span *ngIf="post.isGoogleAd" class="post-stats-title">
        <img
          class="google-logo"
          src="/assets/images/google-ad-logo.svg"
          width="28"
        />
        <span class="card-title">
          {{ "campaign.post.stats.googleAdStats" | translate }}
        </span>
      </span>
      <span *ngIf="post.isFacebookPost" class="post-stats-title">
        <img
          class="facebook-logo"
          src="/assets/images/facebook-post-logo.svg"
          width="28"
        />
        <img
          class="instagram-logo"
          src="/assets/images/instagram-post-logo.svg"
          width="28"
        />
        <span class="card-title">{{
          "campaign.post.stats.adStats" | translate
        }}</span>
      </span>
    </h2>
    <div>
      <small *ngIf="!post.publishedByPlatform" class="stat-last-updated">
        {{ "campaign.post.stats.updateFrequency" | translate }}
      </small>
      <span *ngIf="post.publishedByPlatform" class="stat-last-updated">
        {{ "campaign.post.stats.lastUpdateOn" | translate }}
        {{ post.updatedAt | date: dateTimeFormat() }}.
        <span
          *ngIf="post.isFacebookPost"
          [innerHTML]="
            'campaign.post.stats.disclaimer'
              | translate
                : {
                    href: postUrl,
                    target: post ? '_blank' : ''
                  }
          "
        >
        </span>
      </span>
    </div>
    <span>
      <span class="published-text">{{
        "campaign.post.stats.runningTime" | translate
      }}</span
      ><br />
      <span class="published-date">
        {{ "shared.fromDate" | translate }}
        {{ post.ad?.startDate | localizeDate: "short" }}
        {{ "shared.toDate" | translate }}
        {{ post.ad?.endDate | localizeDate: "short" }}
      </span>
    </span>

    @if (!areStatsValid()) {
      <div class="info-message">
        <mat-icon>info</mat-icon>
        <span>{{ "campaign.post.stats.noValidMetrics" | translate }}</span>
      </div>
    }

    @if (areStatsValid()) {
      <div class="stats-metrics-wrap stats-metrics-wrap--first">
        <div class="stats-metrics-column">
          <p class="single-stat">
            <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
              <span class="stat-text">
                <mat-icon>desktop_mac</mat-icon
                >{{ "shared.impressions" | translate }} </span
              ><br />
              <span class="stat-number">
                {{ post.ad?.metrics?.impressions ?? 0 }}
              </span>
            </a>
          </p>
        </div>
        <div class="stats-metrics-column stats-metrics-column--right">
          <p class="single-stat">
            <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
              <span class="stat-text">
                <img src="/assets/images/icons/ic_views.svg" width="18" />{{
                  "shared.reach" | translate
                }} </span
              ><br />
              <span class="stat-number">
                {{ post.ad?.metrics?.reach ?? 0 }}
              </span>
            </a>
          </p>
        </div>
      </div>
      <div class="stats-metrics-wrap">
        <div class="stats-metrics-column">
          <p
            *ngIf="post.isFacebookPost"
            class="single-stat single-stat--shorter-icon"
          >
            <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
              <span class="stat-text">
                <img src="/assets/images/icons/ic_fb_like.png" width="18" />{{
                  "shared.engagements" | translate
                }} </span
              ><br />
              <span class="stat-number">
                {{ post.ad?.metrics?.inlinePostEngagement ?? 0 }}
              </span>
            </a>
          </p>
          <p
            *ngIf="post.isGoogleAd"
            class="single-stat single-stat--shorter-icon"
          >
            <a *ngIf="post.ad?.metrics?.conversions" [attr.href]="null">
              <span class="stat-text">
                <img src="/assets/images/icons/ic_fb_like.png" width="18" />{{
                  "shared.conversions" | translate
                }} </span
              ><br />
              <span class="stat-number">
                {{ post.ad?.metrics?.conversions ?? 0 }}
              </span>
            </a>
          </p>
        </div>
        <div class="stats-metrics-column stats-metrics-column--right">
          <p class="single-stat">
            <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
              <span class="stat-text">
                <img
                  src="/assets/images/icons/ic_link_clicks.svg"
                  width="18"
                />{{ "shared.clicks" | translate }} </span
              ><br />
              <span class="stat-number">
                {{ post.ad?.metrics?.uniqueClicks ?? 0 }}
              </span>
            </a>
          </p>
        </div>
      </div>
    }
    <div class="stats-metrics-wrap">
      <div class="stats-metrics-column">
        <p class="single-stat single-stat--shorter-icon">
          <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
            <span class="stat-text">
              <img src="/assets/images/icons/ic_spent.svg" width="18" />
              {{ "shared.spent" | translate }}
            </span>
            <br />
            <span class="stat-number">
              {{ post.ad?.metrics?.spend ?? 0 }}
              {{ campaign.currencySymbol }}
            </span>
          </a>
        </p>
      </div>
      <div class="stats-metrics-column stats-metrics-column--right">
        <p class="single-stat single-stat--short-icon">
          <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
            <span class="stat-text">
              <img src="/assets/images/icons/ic_spent.svg" width="18" />
              {{ "shared.budget" | translate }} </span
            ><br />
            <span class="stat-number">
              {{ post.ad?.budget }} {{ campaign.currencySymbol }}
            </span>
          </a>
        </p>
      </div>
    </div>
    <div class="post-stats-content-buttons">
      <a
        (click)="onActionFlipPostAdStats()"
        *ngIf="hasPostStats"
        class="round-button round-button--orange"
      >
        {{ "campaign.post.stats.viewPostStats" | translate }}
      </a>
      <a class="post-stats-ad-config-link" (click)="onActionOpenAdConfig()"
        ><span>
          <img src="/assets/images/icons/ic_config_wheel.svg" width="18"
        /></span>
        {{ "campaign.post.stats.viewAdConfig" | translate }}
      </a>
    </div>
  </div>
</div>
