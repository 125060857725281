import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { Campaign } from "../../../models/campaign";
import { BrandCampaign } from "../../../models/brandCampaign";
import { PostLog } from "../../../models/postLog";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../models/partnerGoogleAdPostLog";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import { BrandGoogleAdPostLog } from "../../../models/brandGoogleAdPostLog";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";
import { MatIconModule } from "@angular/material/icon";
import { getDateTimeFormatSignal } from "../../../services/date.service";

@Component({
  selector: "app-campaign-post-stats-ad",
  templateUrl: "./campaign-post-stats-ad.component.html",
  styleUrl: "./campaign-post-stats-ad.component.scss",
  standalone: true,
  imports: [
    MatIconModule,
    NgClass,
    NgIf,
    SharedPipesModule,
    TranslateModule,
    DatePipe,
  ],
})
export class CampaignPostStatsAdComponent implements OnInit {
  @Input({ required: true }) post!:
    | PartnerFacebookPostLog
    | BrandFacebookPostLog
    | PartnerGoogleAdPostLog
    | BrandGoogleAdPostLog;
  @Input({ required: true }) campaign!: Campaign;
  @Input() isFlippable = false;
  @Input() isVisible = false;
  @Input() hasPostStats = false;

  @Output() openAdConfigDialog = new EventEmitter<PostLog>();
  @Output() flipPostAdStatsEvent = new EventEmitter<void>();

  protected readonly dateTimeFormat = getDateTimeFormatSignal();
  protected readonly areStatsValid = signal(false);
  protected isBrand = false;

  public ngOnInit(): void {
    this.isBrand = this.campaign instanceof BrandCampaign;
    this.areStatsValid.set(
      this.post.hasValidMetrics ||
        (this.post.ad?.metrics?.impressions ?? 0) > 0 ||
        (this.post.ad?.metrics?.reach ?? 0) > 0 ||
        (this.post.ad?.metrics?.inlinePostEngagement ?? 0) > 0 ||
        (this.post.ad?.metrics?.conversions ?? 0) > 0 ||
        (this.post.ad?.metrics?.uniqueClicks ?? 0) > 0,
    );
  }

  public onActionOpenAdConfig(): void {
    this.openAdConfigDialog.emit(this.post);
  }

  public onActionFlipPostAdStats(): void {
    this.flipPostAdStatsEvent.emit();
  }

  public get postUrl(): string {
    if (
      this.post instanceof PartnerFacebookPostLog ||
      this.post instanceof BrandFacebookPostLog
    ) {
      return this.post.facebookPostUrl;
    }

    return this.post.postUrl || "";
  }
}
