@if (needsAcceptance()) {
  <div class="banner">
    <span>
      {{ "partner.campaign.contentCalendar.acceptanceMessage" | translate }}
    </span>
    <button
      class="btn btn--bluegray"
      (click)="onActionAcceptCalendar()"
      [disabled]="isCalendarBeingAccepted()"
    >
      {{ "partner.campaign.contentCalendar.acceptanceAction" | translate }}
    </button>
  </div>
}
