<div
  class="post-stats-list"
  [ngClass]="{
    'post-stats-list--carousel': post.mediaType === CarouselType
  }"
>
  <div class="post-stats post-stats--not-published">
    <div *ngIf="isPromoted" class="promoted-badge">
      {{ "shared.promoted" | translate }}
    </div>
    <div *ngIf="!isPromoted" class="promoted-badge">
      {{ "shared.organic" | translate }}
    </div>
    <div class="post-stats-content">
      <h2>
        <span *ngIf="post.isFacebookPost && !post.hasAd">
          <img
            class="facebook-logo"
            src="/assets/images/facebook-post-logo.svg"
            width="28"
          />
          <span class="card-title">
            {{ "campaign.post.stats.postInformation" | translate }}</span
          >
        </span>
        <span *ngIf="post.isFacebookPost && post.hasAd">
          <img
            class="facebook-logo"
            src="/assets/images/facebook-post-logo.svg"
            width="28"
          />
          <img
            class="instagram-logo"
            src="/assets/images/instagram-post-logo.svg"
            width="28"
          />
          <span class="card-title">
            {{ "campaign.post.stats.adInformation" | translate }}</span
          >
        </span>
        <span *ngIf="post.isGoogleAd">
          <img
            class="google-logo"
            src="/assets/images/google-ad-logo.svg"
            width="28"
          />
          <span class="card-title">{{
            "campaign.post.stats.googleAdInformation" | translate
          }}</span>
        </span>
        <span *ngIf="post.isLinkedInPost">
          <img
            class="linkedin-logo"
            src="/assets/images/icons/linkedin/ic_linkedin.svg"
            width="28"
          />
          <span class="card-title">{{
            "campaign.post.stats.postInformation" | translate
          }}</span>
        </span>
      </h2>
      <div class="to-be-published-on">
        <span class="stat-text"
          >{{ "campaign.post.stats.toBePublishedOn" | translate }}
        </span>
        <span class="stat-date">
          {{ post.scheduledPublishDate | date: "MMM d, y HH:mm" }}
        </span>
      </div>
      <div *ngIf="post.hasAd" class="stats-metrics-wrap">
        <div class="stats-metrics-column">
          <p class="single-stat single-stat--short-icon">
            <a [attr.href]="postUrl" [attr.target]="post ? '_blank' : ''">
              <span class="stat-text">
                <img src="/assets/images/icons/ic_spent.svg" width="18" />{{
                  "shared.budget" | translate
                }} </span
              ><br />
              <span class="stat-number"
                >{{ post.ad.budget }} {{ campaign.currencySymbol }}</span
              >
            </a>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="showActionButtons()" class="buttons-wrapper">
      <button class="btn btn--primary" (click)="showEditPostModal()">
        <mat-icon>access_alarms</mat-icon>
        <span>{{ "campaign.post.stats.changeDate" | translate }}</span>
      </button>
      <button
        *ngIf="canDeleteFacebookAd()"
        class="btn btn--transparent"
        (click)="showCancelPostModal()"
      >
        <mat-icon>delete</mat-icon>
        <span>{{ "campaign.post.stats.deleteFacebookAd" | translate }}</span>
      </button>
      <button
        *ngIf="canDeleteGoogleAd()"
        class="btn btn--transparent"
        (click)="showCancelPostModal()"
      >
        <mat-icon>delete</mat-icon>
        <span>{{ "campaign.post.stats.deleteGoogleAd" | translate }}</span>
      </button>
      <button
        *ngIf="isPostWithNoAd"
        class="btn btn--transparent"
        (click)="showCancelPostModal()"
      >
        <mat-icon>delete</mat-icon>
        <span>{{ "campaign.post.stats.deleteFacebookPost" | translate }}</span>
      </button>
    </div>
  </div>
</div>
