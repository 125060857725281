import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import { PartnerCampaign } from "../../../models/partnerCampaign";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";
import { PostLog } from "../../../models/postLog";
import { CampaignPostContentType } from "../../../enums/campaignPost.enums";
import { Campaign } from "../../../models/campaign";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";

@Component({
  selector: "app-campaign-post-stats-not-published",
  templateUrl: "./campaign-post-stats-not-published.component.html",
  styleUrl: "./campaign-post-stats-not-published.component.scss",
  standalone: true,
  imports: [
    MatIconModule,
    NgClass,
    NgIf,
    SharedPipesModule,
    TranslateModule,
    DatePipe,
  ],
})
export class CampaignPostStatsNotPublishedComponent {
  @Input({ required: true }) post!: PostLog;
  @Input({ required: true }) campaign!: Campaign;
  @Output() openCancelPostDialog = new EventEmitter<PostLog>();
  @Output() openEditPostDialog = new EventEmitter<PostLog>();

  public readonly CarouselType = CampaignPostContentType.Carousel;

  public showCancelPostModal(): void {
    this.openCancelPostDialog.emit(this.post);
  }

  public showEditPostModal(): void {
    this.openEditPostDialog.emit(this.post);
  }

  public showActionButtons(): boolean {
    return this.campaign instanceof PartnerCampaign;
  }

  public canDeleteFacebookAd(): boolean {
    if (this.post.ad && this.post.ad.isPaidAd) {
      return false;
    }
    return this.post.isFacebookPost && this.post.hasAd;
  }

  public canDeleteGoogleAd(): boolean {
    if (this.post.ad && this.post.ad.isPaidAd) {
      return false;
    }
    return this.post.isGoogleAd;
  }

  public get isPromoted(): boolean {
    if (this.post.isGoogleAd) {
      return true;
    }

    return (
      (this.post as PartnerFacebookPostLog).willBecomeAd || this.post.hasAd
    );
  }

  public get postUrl(): string {
    if (
      this.post instanceof PartnerFacebookPostLog ||
      this.post instanceof BrandFacebookPostLog
    ) {
      return this.post.facebookPostUrl;
    }

    return this.post.postUrl || "";
  }

  public get isPostWithNoAd(): boolean {
    return (
      this.post.isLinkedInPost || (this.post.isFacebookPost && !this.post.hasAd)
    );
  }
}
