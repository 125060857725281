<div
  [ngClass]="{
    'post-stats-box--front': post.hasAd && isFlippable,
    'is-flipped': !isVisible
  }"
  class="post-stats post-stats-box post-stats-box--side"
  [attr.data-post-log-id]="post.id"
  [attr.data-ad-id]="post.ad?.id"
>
  <div [ngClass]="{ 'badge-error': hasPostingError }" class="promoted-badge">
    <span *ngIf="!hasPostingError" class="promoted-badge__text">
      {{ "shared.organic" | translate }}
    </span>
    <span class="promoted-badge__text" *ngIf="post.postingToPlatformFailed">
      {{ "campaign.post.stats.postCreationError" | translate }}
    </span>
    <span
      class="promoted-badge__text"
      *ngIf="post.isMissingFacebookPermissions"
    >
      {{ "campaign.post.stats.connectToFacebook" | translate }}
    </span>

    <app-tooltip
      *ngIf="hasPostingError"
      class="promoted-badge__tooltip"
      [icon]="ToolTipIcon.Warning"
      text="{{ reconnectToFacebookText | translate }}"
    >
    </app-tooltip>
  </div>
  <div
    [ngClass]="{
      'not-promoted': post.hasAd,
      promoted: !post.hasAd
    }"
    class="post-stats-content"
  >
    <div class="post-stats-header-box">
      <div
        *ngIf="post.postToFacebook"
        [ngClass]="{
          'post-stats-tab--multiple':
            post.postToFacebook && post.postToInstagram
        }"
        class="post-stats-tab"
      >
        <div
          (click)="onActionShowFacebookStats()"
          [ngClass]="{
            'post-stats-tab-button--selected': showFacebookStats
          }"
          class="post-stats-tab-button"
        >
          <img src="/assets/images/facebook-post-logo.svg" />
          <span class="post-stats-tab-button-text">
            {{ "campaign.post.stats.postStats" | translate }}
          </span>
        </div>
      </div>
      <div
        *ngIf="isPublishedToInstagram"
        [ngClass]="{
          'post-stats-tab--multiple':
            post.postToFacebook && post.postToInstagram
        }"
        class="post-stats-tab"
      >
        <div
          (click)="onActionShowInstagramStats()"
          [ngClass]="{ 'post-stats-tab-button--selected': showInstagramStats }"
          class="post-stats-tab-button"
        >
          <img src="/assets/images/instagram-post-logo.svg" />
          <span class="post-stats-tab-button-text">
            {{ "campaign.post.stats.postStats" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="post-stats-post-info">
      <span *ngIf="!post.publishedByPlatform">
        {{ "campaign.post.stats.updateFrequency" | translate }}
      </span>
      <span *ngIf="post.publishedByPlatform" class="published-grid">
        <span>
          {{ "campaign.post.stats.lastUpdateOn" | translate }}
          {{ post.updatedAt | date: dateTimeFormat() }}.
        </span>

        @if (
          (showInstagramStats && !areInstagramStatsValid()) ||
          (showFacebookStats && !areFacebookStatsValid())
        ) {
          <div class="info-message">
            <mat-icon>info</mat-icon>
            <span>{{ "campaign.post.stats.noValidMetrics" | translate }}</span>
          </div>
        }

        <span
          *ngIf="showFacebookStats"
          [innerHTML]="
            'campaign.post.stats.disclaimer'
              | translate: { href: post.facebookPostUrl, target: '_blank' }
          "
        >
        </span>
        <span
          *ngIf="showInstagramStats"
          [innerHTML]="
            'campaign.post.stats.disclaimerInstagram'
              | translate: { href: post.instagramPostUrl, target: '_blank' }
          "
        >
        </span>
      </span>
    </div>
    <div
      *ngIf="showInstagramStats"
      class="metrics-grid metrics-grid--instagram"
    >
      <div>
        <div class="metric-published">
          {{ "shared.published" | translate }}
        </div>
        <div class="metric-date">
          {{ post.scheduledPublishDate | date: "MMM d, y HH:mm" }}
        </div>
      </div>
      @if (areInstagramStatsValid()) {
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_reach.svg" />
            <span>{{ "shared.reach" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.instagramReach }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_engagement.svg" />
            <span>{{ "shared.engagement" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.instagramEngagement }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_impressions.svg" />
            <span>{{ "shared.impressions" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.instagramImpressions }}
          </div>
        </div>
        <div *ngIf="post.mediaType === ContentType.Video">
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_views.svg" />
            <span>{{ "shared.views" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.instagramVideoViews }}
          </div>
        </div>
      }
    </div>

    <div *ngIf="showFacebookStats" class="metrics-grid metrics-grid--facebook">
      <div>
        <div class="metric-published">
          {{ "shared.published" | translate }}
        </div>
        <div class="metric-date">
          {{ post.scheduledPublishDate | date: "MMM d, y HH:mm" }}
        </div>
      </div>
      @if (areFacebookStatsValid()) {
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_like.png" />
            <span>{{ "shared.likes" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.likes }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_comment.png" />
            <span>{{ "shared.comments" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.comments }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_share.png" />
            <span>{{ "shared.shares" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.shares }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_link_clicks.svg" />
            <span>{{ "shared.clicks" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.clicks }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_views.svg" />
            <span>{{ "shared.views" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.impressions }}
          </div>
        </div>
      }
    </div>
    <div
      *ngIf="
        post.postToFacebook && post.postToInstagram && post.instagramAccountId
      "
      class="tab-indicators"
    >
      <div
        [ngClass]="{ 'tab-indicators-dot--selected': showFacebookStats }"
        class="tab-indicators__dot"
      ></div>
      <div
        [ngClass]="{ 'tab-indicators-dot--selected': showInstagramStats }"
        class="tab-indicators__dot"
      ></div>
    </div>

    <div class="post-stats-buttons">
      <a
        (click)="openPromotePost()"
        *ngIf="showPromotePostButton"
        class="btn btn--primary"
      >
        {{ "campaign.post.stats.promotePost" | translate }}
      </a>
      <a
        (click)="onActionFlipPostAdStats()"
        *ngIf="post.hasAd"
        class="btn btn--primary"
      >
        {{ "campaign.post.stats.viewAdStats" | translate }}
      </a>
    </div>
  </div>
</div>
