<div
  class="post-stats post-stats-box post-stats-box--side"
  [attr.data-post-log-id]="post.id"
>
  <div
    [ngClass]="{
      'badge-error':
        post.isMissingLinkedInPermissions || post.postingToPlatformFailed
    }"
    class="promoted-badge"
  >
    <span
      class="promoted-badge__text"
      *ngIf="
        !post.isMissingLinkedInPermissions && !post.postingToPlatformFailed
      "
    >
      {{ "shared.organic" | translate }}
    </span>
    <span class="promoted-badge__text" *ngIf="post.postingToPlatformFailed">
      {{ "campaign.post.stats.postCreationError" | translate }}
    </span>
    <span
      class="promoted-badge__text"
      *ngIf="post.isMissingLinkedInPermissions"
    >
      {{ "campaign.post.stats.connectToFacebook" | translate }}
    </span>

    <app-tooltip
      *ngIf="post.isMissingLinkedInPermissions || post.postingToPlatformFailed"
      class="promoted-badge__tooltip"
      [icon]="ToolTipIcon.Warning"
      text="{{ reconnectToFacebookText | translate }}"
    >
    </app-tooltip>
  </div>
  <div class="post-stats-content">
    <div class="post-stats__header">
      <img src="/assets/images/icons/linkedin/ic_linkedin_logo.svg" />
      <span>{{ "campaign.post.stats.postStats" | translate }}</span>
    </div>
    <div class="post-stats__post-info">
      <ng-container *ngIf="!post.publishedByPlatform">
        {{ "campaign.post.stats.updateFrequency" | translate }}
      </ng-container>
      <ng-container *ngIf="post.publishedByPlatform">
        {{ "campaign.post.stats.lastUpdateOn" | translate }}
        {{ post.updatedAt | date: dateTimeFormat() }}.
        <span
          [innerHTML]="'campaign.post.stats.disclaimerLinkedIn' | translate"
        ></span>
      </ng-container>
    </div>

    @if (!areStatsValid()) {
      <div class="info-message">
        <mat-icon>info</mat-icon>
        <span>{{ "campaign.post.stats.noValidMetrics" | translate }}</span>
      </div>
    }

    <div class="metrics-grid">
      <div>
        <a [href]="post.postUrl" target="_blank">
          <div class="metric-published">
            {{ "shared.published" | translate }}
          </div>
          <div class="metric-date">
            {{ post.scheduledPublishDate | date: "MMM d, y HH:mm" }}
          </div>
        </a>
      </div>
      @if (areStatsValid()) {
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_like.png" />
            <span>{{ "shared.likes" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.likes }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_comment.png" />
            <span>{{ "shared.comments" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.comments }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_fb_share.png" />
            <span>{{ "shared.shares" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.shares }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_link_clicks.svg" />
            <span>{{ "shared.clicks" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.clicks }}
          </div>
        </div>
        <div>
          <div class="metric-label">
            <img src="/assets/images/icons/post-stats/ic_views.svg" />
            <span>{{ "shared.views" | translate }}</span>
          </div>
          <div class="metric-number">
            {{ post.impressions }}
          </div>
        </div>
      }
    </div>
  </div>
</div>
