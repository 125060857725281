import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Campaign } from "../../models/campaign";
import { PostLog } from "../../models/postLog";
import { PartnerFacebookPostLog } from "../../models/partnerFacebookPostLog";

import {
  CampaignPostContentType,
  PostLogStatus,
} from "../../enums/campaignPost.enums";
import { CampaignPostStatsAdComponent } from "./campaign-post-stats-ad/campaign-post-stats-ad.component";
import { CampaignPostStatsFacebookPostComponent } from "./campaign-post-stats-facebook-post/campaign-post-stats-facebook-post.component";
import { CampaignPostStatsLinkedinPostComponent } from "./campaign-post-stats-linkedin-post/campaign-post-stats-linkedin-post.component";
import { CampaignPostStatsNotPublishedComponent } from "./campaign-post-stats-not-published/campaign-post-stats-not-published.component";
import { NgClass, NgIf } from "@angular/common";

enum CampaignPostStatsView {
  PostStats = "post-stats",
  AdStats = "ad-stats",
}

@Component({
  selector: "app-campaign-post-stats",
  templateUrl: "./campaign-post-stats.component.html",
  styleUrl: "./campaign-post-stats.component.scss",
  standalone: true,
  animations: [
    trigger("flipStatsState", [
      state(
        CampaignPostStatsView.AdStats,
        style({
          transform: "rotateY(180deg)",
        }),
      ),
      state(
        CampaignPostStatsView.PostStats,
        style({
          transform: "rotateY(0deg)",
        }),
      ),
      transition(
        CampaignPostStatsView.AdStats +
          " => " +
          CampaignPostStatsView.PostStats,
        animate("500ms ease-out"),
      ),
    ]),
  ],
  imports: [
    CampaignPostStatsAdComponent,
    CampaignPostStatsFacebookPostComponent,
    CampaignPostStatsLinkedinPostComponent,
    CampaignPostStatsNotPublishedComponent,
    NgClass,
    NgIf,
  ],
})
export class CampaignPostStatsComponent implements OnInit {
  @Input({ required: true }) post!: PostLog;
  @Input({ required: true }) campaign!: Campaign;
  @Input({ required: true }) type!: PostLogStatus;

  @Output() openPromoteAdDialog = new EventEmitter<PartnerFacebookPostLog>();
  @Output() openAdConfigDialog = new EventEmitter<PostLog>();
  @Output() openUpdateFacebookConnectionDialog = new EventEmitter<PostLog>();
  @Output() openEditPostDialog = new EventEmitter<PostLog>();
  @Output() openCancelPostDialog = new EventEmitter<PostLog>();

  protected readonly PostLogStatus = PostLogStatus;
  protected TYPE_CAROUSEL = CampaignPostContentType.Carousel;
  protected isShowingBack = false;
  protected statsView = CampaignPostStatsView.PostStats;

  private showAdStats = false;

  public ngOnInit(): void {
    if (this.post.hasAd) {
      this.showAdStatsView();
    }
  }

  protected togglePostAdStats(): void {
    if (this.showAdStats) {
      this.showAdStats = false;
      setTimeout(() => (this.isShowingBack = false), 200);
      this.statsView = CampaignPostStatsView.PostStats;
    } else {
      this.showAdStatsView();
    }
  }

  private showAdStatsView(): void {
    this.showAdStats = true;
    setTimeout(() => (this.isShowingBack = true), 200);
    this.statsView = CampaignPostStatsView.AdStats;
  }

  protected openPromotePost(post: PartnerFacebookPostLog): void {
    this.openPromoteAdDialog.emit(post);
  }

  protected openAdConfig(): void {
    this.openAdConfigDialog.emit(this.post);
  }

  protected openEditPost(): void {
    this.openEditPostDialog.emit(this.post);
  }

  protected openCancelPost(): void {
    this.openCancelPostDialog.emit(this.post);
  }

  protected showPostFlippableStats(post: PostLog): boolean {
    if (!post) {
      return false;
    } else if (this.type !== PostLogStatus.Published) {
      return false;
    } else if (!post.isFacebookPost) {
      return false;
    }

    const facebookPost = post as PartnerFacebookPostLog;
    return facebookPost.hasAd && !facebookPost.isDarkPost;
  }
}
