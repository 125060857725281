import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { Campaign } from "../../../models/campaign";
import { AdActionType } from "../../../enums/campaign.enums";
import {
  CampaignPostContentType,
  PostTargetPlatforms,
} from "../../../enums/campaignPost.enums";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";
import { PartnerCampaign } from "../../../models/partnerCampaign";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { getDateTimeFormatSignal } from "../../../services/date.service";
import { ToolTipIcon, TooltipComponent } from "../../tooltip/tooltip.component";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";

@Component({
  selector: "app-campaign-post-stats-facebook-post",
  templateUrl: "./campaign-post-stats-facebook-post.component.html",
  styleUrl: "./campaign-post-stats-facebook-post.component.scss",
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    SharedPipesModule,
    TranslateModule,
    TooltipComponent,
    DatePipe,
    MatIcon,
  ],
})
export class CampaignPostStatsFacebookPostComponent implements OnInit {
  @Input({ required: true }) public post!:
    | PartnerFacebookPostLog
    | BrandFacebookPostLog;
  @Input({ required: true }) public campaign!: Campaign;
  @Input() public isFlippable = false;
  @Input() public isVisible = true;

  @Output()
  openPromoteAdDialog = new EventEmitter<PartnerFacebookPostLog>();
  @Output() openUpdateFacebookConnectionDialog =
    new EventEmitter<PartnerFacebookPostLog>();
  @Output() flipPostAdStatsEvent = new EventEmitter<void>();

  protected readonly dateTimeFormat = getDateTimeFormatSignal();
  protected readonly ToolTipIcon = ToolTipIcon;
  protected readonly ContentType = CampaignPostContentType;
  protected statsMediaView = PostTargetPlatforms.Facebook;
  protected reconnectToFacebookText = "campaign.post.stats.connectToFacebook";
  protected isPromoteAdDisabled = false;
  protected readonly areInstagramStatsValid = signal(false);
  protected readonly areFacebookStatsValid = signal(false);

  public ngOnInit(): void {
    this.setCurrentMediaPreview();

    if (this.campaign instanceof PartnerCampaign) {
      this.isPromoteAdDisabled =
        this.campaign.adActionType === AdActionType.Boost &&
        (this.post.mediaType === CampaignPostContentType.Gif ||
          this.post.mediaType === CampaignPostContentType.Image360);
    } else {
      this.reconnectToFacebookText = "campaign.post.stats.partnerNotConnected";
    }

    if (this.post instanceof PartnerFacebookPostLog) {
      this.areInstagramStatsValid.set(
        this.post.hasValidMetrics ||
          this.post.instagramReach > 0 ||
          this.post.instagramEngagement > 0 ||
          this.post.instagramVideoViews > 0,
      );
    }

    this.areFacebookStatsValid.set(
      this.post.hasValidMetrics ||
        this.post.likes > 0 ||
        this.post.impressions > 0 ||
        this.post.clicks > 0 ||
        this.post.comments > 0 ||
        this.post.shares > 0,
    );
  }

  public openPromotePost(): void {
    this.openPromoteAdDialog.emit(this.post as PartnerFacebookPostLog);
  }

  public get showPromotePostButton(): boolean {
    if (
      !(this.campaign instanceof PartnerCampaign) ||
      this.campaign.hasFinished ||
      !this.campaign.promoAdsEnabled ||
      !this.post ||
      this.isPromoteAdDisabled
    ) {
      return false;
    }
    return !this.post.hasAd;
  }

  public onActionFlipPostAdStats(): void {
    this.flipPostAdStatsEvent.emit();
  }

  public get showFacebookStats(): boolean {
    return this.statsMediaView === PostTargetPlatforms.Facebook;
  }

  public get showInstagramStats(): boolean {
    return this.statsMediaView === PostTargetPlatforms.Instagram;
  }

  public onActionShowFacebookStats(): void {
    this.statsMediaView = PostTargetPlatforms.Facebook;
  }

  public onActionShowInstagramStats(): void {
    this.statsMediaView = PostTargetPlatforms.Instagram;
  }

  public get isPublishedToInstagram(): boolean {
    const post = this.post as PartnerFacebookPostLog;
    return post.postToInstagram && post.isPublishedByInstagram;
  }

  public setCurrentMediaPreview(): void {
    this.statsMediaView = this.post.postToFacebook
      ? PostTargetPlatforms.Facebook
      : PostTargetPlatforms.Instagram;
  }

  public get hasPostingError(): boolean {
    return Boolean(
      this.post.isMissingFacebookPermissions ||
        this.post.postingToPlatformFailed,
    );
  }
}
