import {
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationService } from "../../../../shared/content/data/publication.service";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { CheckId } from "../../../partner-campaign/partner-check-third-party-connections-dialog/partner-check-third-party-connections-dialog.view-state";
import { CountPendingPublicationIntentsInteractor } from "../../../partner-campaign/publication-intent/domain/interactors/count-pending-publication-intents.interactor";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  standalone: true,
  selector: "app-partner-calendar-acceptance-banner",
  templateUrl: "./partner-calendar-acceptance-banner.component.html",
  styleUrl: "./partner-calendar-acceptance-banner.component.scss",
  imports: [TranslateModule],
})
export class PartnerCalendarAcceptanceBannerComponent implements OnInit {
  @Output() public readonly calendarAccepted = new EventEmitter<void>();
  protected readonly needsAcceptance = signal(false);
  private readonly countPendingPublicationIntents = inject(
    CountPendingPublicationIntentsInteractor,
  );
  private readonly partnerId = inject(PartnerService).currentPartnerId;
  private readonly publicationService = inject(PublicationService);
  private readonly partnerDialogService = inject(PartnerDialogService);
  private readonly notificationService = inject(NotificationService);
  private readonly campaign = inject(PartnerCampaignService)
    .currentPartnerCampaign;
  protected readonly isCalendarBeingAccepted = signal(false);

  public async ngOnInit(): Promise<void> {
    const countPending = await this.countPendingPublicationIntents.execute(
      this.campaign.id,
      this.partnerId,
    );
    this.needsAcceptance.set(countPending !== 0);
  }

  protected async onActionAcceptCalendar(): Promise<void> {
    this.isCalendarBeingAccepted.set(true);

    const isPartnerConnected =
      await this.partnerDialogService.showThirdPartyConnectionsCheck({
        context: "calendar",
        thirdPartyConnections: [CheckId.FbConnection, CheckId.IgConnection],
      });

    if (!isPartnerConnected) {
      this.isCalendarBeingAccepted.set(false);
      return;
    }

    try {
      await this.publicationService.acceptCalendar(
        this.partnerId,
        this.campaign.id,
        this.campaign.currentDetails!.id,
        this.campaign.currentCalendar!.id,
        {
          posts: [],
        },
      );

      this.calendarAccepted.emit();
    } catch (error) {
      console.error(error);
    }

    this.notificationService.success(
      "publicationTemplate.schedule.calendarAcceptedSuccess",
    );
    this.isCalendarBeingAccepted.set(false);
  }
}
